import AbpAppService from "api/AbpAppService";
import { IStatistics } from "routes/app/charts/Orders";
import { IAbpResponseGet } from "types/IApi";
import { IUser } from "types/IUser";
import { getResponseExceptionMessage } from "./apiSettings";
import { ICompany } from "./company";
import { IConfiguration } from "./configuration";
import { IDepth } from "./depth";
import { IFurniture } from "./furniture";
import { IMerch } from "./merch";
import { IStoreDto } from "./store";

export interface IOrder {
  id: string;
  reference: string;
  orderStatus: OrderStatusEnum;
  company?: ICompany;
  companyLabel: string;
  furniture?: IFurniture;
  furnitureLabel: string;
  depth?: IDepth;
  depthLabel: string;
  configuration?: IConfiguration;
  configurationLabel: string;
  merch?: IMerch;
  address: string;
  city: string;
  postalCode: string;
  loadingDate: string;
  deliveryDate: string;
  orderStatusLines: IOrderStatusLine[];
  creationTime: string;
  store: IStoreDto;
  storeLabel: string;
  storeReference: string;
  contactEmail: string;
  contactName: string;
  contactPhoneNumber: string;
  linearGain: string;
  lastImplantation?: string;
  expectedDeliveryDate?: string;
  signatureName?: string;
  signature?: string;
  creatorUser?: IUser;
  orderFormId?: string;
  orderFormSignedId?: string;
  deliveryFormId?: string;
  markupFileId?: string;
  completionDate?: string;
  factoryRequestId?: string;
  totalHT?: number;
  totalTTC?: number;
  totalWeight?: number;
  isGoodDeliveryDate?: boolean;
  image?: string;
}

export interface IOrderStatusLine {
  id: string;
  creationTime: string;
  orderStatus: OrderStatusEnum;
  creatorUser: IUser;
  comment?: string;
}

export enum OrderStatusEnum {
  Draft = 1, // En cours
  Signature = 2, // En attente de signature
  RRSValidation = 3, // En attente de validation du RRS
  CVRValidation = 4, // En attente de validation du CVR
  DZValidation = 5, // En attente de validation du DZ
  MerchValidation = 6, // En attente de validation du Merch
  Preparation = 12, // En cours de préparation
  Delivering = 7, // En cours de livraison
  Done = 8, // Terminée
  Refused = 9, // Refusée
  // Delayed = 10, //  Retardée
  Canceled = 11, // Annulée
  HasTracking = 13, // A des QrCodes associés, souvent égal à 'Transfert activé'
}

export const OrderStatusLabels = {
  [OrderStatusEnum.Draft]: "En cours",
  [OrderStatusEnum.Signature]: "En attente de signature",
  [OrderStatusEnum.RRSValidation]: "En attente de validation du RRS",
  [OrderStatusEnum.CVRValidation]: "En attente de validation du CVR",
  [OrderStatusEnum.DZValidation]: "En attente de validation du DZ",
  [OrderStatusEnum.MerchValidation]: "En attente de validation du Merch",
  [OrderStatusEnum.Preparation]: "En cours de préparation",
  [OrderStatusEnum.Delivering]: "En cours de livraison",
  [OrderStatusEnum.Done]: "Terminée",
  [OrderStatusEnum.Refused]: "Refusée",
  // [OrderStatusEnum.Delayed]: "Retardée",
  [OrderStatusEnum.Canceled]: "Annulée",
  [OrderStatusEnum.HasTracking]: "Transfert en cours",
};

export const OrderStatusColors = {
  [OrderStatusEnum.Draft]: "#A9ADAA",
  [OrderStatusEnum.Signature]: "#A9ADAA",
  [OrderStatusEnum.RRSValidation]: "#A9ADAA",
  [OrderStatusEnum.CVRValidation]: "#A9ADAA",
  [OrderStatusEnum.DZValidation]: "#A9ADAA",
  [OrderStatusEnum.MerchValidation]: "#A9ADAA",
  [OrderStatusEnum.Preparation]: "#58B8DC",
  [OrderStatusEnum.Delivering]: "#58B8DC",
  [OrderStatusEnum.Done]: "#4EE683",
  [OrderStatusEnum.Refused]: "#DC5858",
  // [OrderStatusEnum.Delayed]: "#DCA658",
  [OrderStatusEnum.Canceled]: "#DC5858",
  [OrderStatusEnum.HasTracking]: "#FF7F50",
};
export interface ICreateOrder extends Omit<IOrder, "id"> {}

// export const defaultEntity: ICreateOrder = {
// };

export const service = new AbpAppService<IOrder, IOrder>("Order");

export const getStatistics = async (params: {}): Promise<
  IAbpResponseGet<IStatistics<OrderStatusEnum>>
> => {
  let result = {} as IAbpResponseGet<IStatistics<OrderStatusEnum>>;
  try {
    result = await service.invoke("get", "GetStatistics", params);
  } catch (error) {
    console.log(getResponseExceptionMessage(error));
  }
  return result;
};

export interface BillingInput {
  loadingDate: string;
  furnitureId: string;
  billable: boolean;
}

export interface BillingOrder {
  order: IOrder;
  furnitureTotal: number;
  transportTotal: number;
}

export interface BillingOutput {
  billingOrders: BillingOrder[];
}

export const getBilling = async (
  params: BillingInput
): Promise<IAbpResponseGet<BillingOutput>> => {
  let result = {} as IAbpResponseGet<BillingOutput>;
  try {
    result = await service.invoke("get", "GetBilling", params);
  } catch (error) {
    console.log(getResponseExceptionMessage(error));
  }
  return result;
};

export interface IOrderTrackingInput
{
    orderId: string;
}

export interface IOrderTrackingOutput
{
    trackings: string[];
}

export const getOrderTracking = async (
  params: IOrderTrackingInput
): Promise<IAbpResponseGet<IOrderTrackingOutput>> => {
  let result = {} as IAbpResponseGet<IOrderTrackingOutput>;
  try {
    result = await service.invoke("get", "GetTrackings", params);
  } catch (error) {
    console.log(getResponseExceptionMessage(error));
  }
  return result;
};